// Create a theme instance.
import {
  responsiveFontSizes,
  unstable_createMuiStrictModeTheme,
} from '@mui/material/styles'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import { commonColors } from './theme'

const typography: TypographyOptions = {
  h1: {
    fontFamily: "'Montserrat', sans-serif",
    textTransform: 'initial',
    fontWeight: 300,
    fontSize: '4rem',
  },
  h2: {
    fontFamily: "'Montserrat', sans-serif",
    textTransform: 'initial',
    fontWeight: 300,
  },
  h3: {
    fontFamily: "'Montserrat', sans-serif",
  },
  h4: {
    fontFamily: "'Montserrat', sans-serif",
  },
  h5: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 400,
  },
  h6: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 400,
  },
  body1: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 300,
  },
  body2: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 300,
  },
  subtitle1: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 600,
  },
  subtitle2: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 600,
  },
}

export const createMaterialTheme = (isDarkMode: boolean) =>
  responsiveFontSizes(
    unstable_createMuiStrictModeTheme({
      palette: {
        common: {
          black: commonColors.black,
          white: commonColors.white,
        },
        background: {
          default: isDarkMode
            ? commonColors.black
            : commonColors.white,
          paper: isDarkMode
            ? commonColors.paperBlack
            : commonColors.paperWhite,
        },
        primary: {
          light: commonColors.primaryColorLight,
          main: commonColors.primaryColor,
          dark: commonColors.primaryColorDark,
        },
        secondary: {
          light: commonColors.secondaryColorLight,
          main: commonColors.secondaryColor,
          dark: commonColors.secondaryColorDark,
        },
        error: { light: '#e57373', main: '#f44336', dark: '#d32f2f' },
        mode: isDarkMode ? 'dark' : 'light',
      },
      typography: {
        ...typography,
        button: {
          fontFamily: "'Montserrat', sans-serif",
        },
      },
      breakpoints: {
        // todo set these in css variables
        /* adjusting small to 650 from 600 */
        values: { xs: 0, sm: 650, md: 960, lg: 1280, xl: 1920 },
      },
      mixins: {
        container: {
          position: 'relative',
          width: '100%',
          maxWidth: '1280px',
          margin: '0 auto',
        },
        perfectText: {
          wordWrap: 'normal',
          overflowWrap: 'normal',
          whiteSpace: 'pre-wrap',
          wordBreak: 'keep-all',
          hyphens: 'auto',
        },
        visuallyHidden: {
          'clip': 'rect(1px, 1px, 1px, 1px)',
          'visibility': 'hidden',
          'position': 'absolute',
          'insetBlockStart': 0,
          'overflow': 'hidden',
          'height': '1px',
          'width': '1px',
          'padding': 0,
          'margin': 0,
          '&:focus': {
            clip: 'auto',
            height: 'auto',
            overflow: 'auto',
            position: 'absolute',
            width: 'auto',
            visibility: 'visible',
            border: '1px solid',
          },
        },
        menuHeaderFont: {
          fontFamily: "'Bebas Neue', cursive",
        },
        menuTitleFont: {
          fontFamily: "'Bebas Neue Book', cursive",
          fontWeight: 600,
        },
        cursiveFont: {
          fontFamily: "'Herr Von Muellerhoff', cursive",
        },
        // for images used in background
        backgroundImage: {
          '& span': {
            width: '100% !important',
            height: '100% !important',
            position: 'relative',
            display: 'block !important',
          },
          '& img': {
            width: '100%',
            height: '100%',
          },
        },
      },
    }),
  )
