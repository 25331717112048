import cookie from 'cookie'
import { NextPageContext } from 'next'
import { default as Cookie } from 'nookies'
import { isServer } from '@Utils/common/is-server'
import { IApp } from '@Types/typings/_app'
import { ServerContext } from '@Types/props/server-context'

export class CookieService {
  private cookieStorage: typeof Cookie

  constructor() {
    this.cookieStorage = Cookie
  }

  public getItem(
    name: string,
    ctx?: IApp.AppCtx | NextPageContext | ServerContext,
  ): string | undefined {
    return isServer()
      ? this.cookieStorage.get(
          ctx && 'ctx' in ctx
            ? ctx.ctx || undefined
            : ctx || undefined,
          {},
        )[name]
      : this.cookieStorage?.get(null)[name]
  }

  public setItem(
    name: string,
    value: string | any,
    options?: cookie.CookieSerializeOptions,
    ctx?: IApp.AppCtx['ctx'] | NextPageContext | ServerContext,
  ) {
    return isServer()
      ? this.cookieStorage.set(
          ctx,
          name,
          value,
          Object.assign(
            {
              path: '/',
              sameSite: 'strict',
            },
            options,
          ),
        )
      : this.cookieStorage.set(
          null,
          name,
          value,
          Object.assign(
            {
              path: '/',
              sameSite: 'strict',
            },
            options,
          ),
        )
  }

  public removeItem(
    name: string,
    options?: cookie.CookieSerializeOptions,
    ctx?: IApp.AppCtx | NextPageContext | ServerContext,
  ) {
    isServer()
      ? this.cookieStorage.destroy(
          // all it needs is res. the types are too broadly set as NextPageContext in cookieMan's type declaration.
          ctx && 'ctx' in ctx ? ctx.ctx : (ctx as any),
          name,
          options,
        )
      : this.cookieStorage.destroy(null, name, options)
  }
}

export const cookieService = new CookieService()
