import dynamic from 'next/dynamic'
import Link from 'next/link'
import { motion } from 'framer-motion'
import { useMediaQuery } from '@mui/material'
import { styled, Theme } from '@mui/material/styles'

import { Logo } from '@Components/elements/Logo/logo'
import { childrenVariants } from '@Config/framer/variants'
import { transitionChildren } from '@Config/framer/transitions'
import { useMemo } from 'react'

// conditional imports save bandwidth!
const DesktopNav = dynamic(() => import('./Desktop'))

const MobileNav = dynamic(() => import('./Mobile'))

interface Header {
  theme: Theme
}

const PREFIX = 'header'

const HiddenLink = styled(motion.a)(({ theme }) => ({
  ...theme.mixins.visuallyHidden,
}))

const classes = {
  logoWrap: `${PREFIX}-logoWrap`,
  menuWrap: `${PREFIX}-menuWrap`,
}

const HeaderWrap = styled('header')(({ theme }) => ({
  ...theme.mixins.container,
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'space-between',
  },
  [`& .${classes.logoWrap}`]: {
    'margin': 0,
    'padding': theme.spacing(1),
    'filter':
      'drop-shadow(1px 1px rgba(180, 180, 180, 0.5)) brightness(0.9) opacity(0.8)',
    '@media (prefersReducedMotion: no-preference)': {
      transition: 'filter 200ms cubic-bezier(0.165, 0.84, 0.44, 1)',
    },
    '&:hover, &:focus': {
      filter:
        'drop-shadow(2px 0px 6px rgba(240, 240, 240, 0.5)) brightness(1) opacity(1)',
    },
  },
  [`& .${classes.menuWrap}`]: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0.5),
  },
}))

export function Header({ theme }: Header) {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  return useMemo(
    () => (
      <>
        <HiddenLink variants={childrenVariants} href={'#main'}>
          Jump directly to main content
        </HiddenLink>
        <HeaderWrap>
          <Link passHref href={'/'}>
            <motion.a
              variants={childrenVariants}
              transition={transitionChildren}
              className={classes.logoWrap}
            >
              <Logo />
            </motion.a>
          </Link>
          <div className={classes.menuWrap}>
            {isSmallScreen ? <MobileNav /> : <DesktopNav />}
          </div>
        </HeaderWrap>
      </>
    ),
    [isSmallScreen],
  )
}
