export type EmailLists = ReadonlyMap<string, { id: string }>

export enum NewsletterListNames {
  bar = 'bar',
  art = 'art',
  botanicals = 'botanicals',
}

export enum NewsletterPromoCodes {
  freekava2021 = 'freekava2021',
}

export type NewsletterList = 'bar' | 'art' | 'botanicals'

export interface AddToMailingListReqBody {
  list: NewsletterList
  email: string
  first_name: string | null
  last_name: string | null
}
