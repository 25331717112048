import { NextSeoProps } from 'next-seo'

//todo organization defintions for art shop / cafe
export const nextSeoConfig: NextSeoProps = {
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: 'https://www.zcospaceclubhouse.com',
    site_name: 'Z',
    title: 'Z Co-space & Clubhouse',
  },
  title: 'A space to immerse yourself',
  titleTemplate: '%s | Z Co-space & Clubhouse',
}
