import { styled } from '@mui/material/styles'
import { Button, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { childrenVariants } from '@Config/framer/variants'
import { transitionChildrenFast } from '@Config/framer/transitions'
import clsx from 'clsx'
import { Fragment } from 'react'

const PREFIX = 'footer'

const classes = {
  section: `${PREFIX}-section`,
  title: `${PREFIX}-title`,
  block: `${PREFIX}-block`,
  hoursTable: `${PREFIX}-hoursTable`,
  hourTitle: `${PREFIX}-hourTitle`,
  hourInfo: `${PREFIX}-hourInfo`,
  newhoursTable: `${PREFIX}-newhoursTable`,
  newhourTitle: `${PREFIX}-newhourTitle`,
  newhourInfo: `${PREFIX}-newhourInfo`,
  mapWrap: `${PREFIX}-mapWrap`,
  button: `${PREFIX}-button`,
}

const FooterWrap = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  [`& .${classes.section}`]: {
    ...theme.mixins.container,
    padding: theme.spacing(1, 1.5, 6),
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      flexFlow: 'column nowrap',
    },
  },

  [`& .${classes.title}`]: {
    fontVariant: 'petite-caps',
    textAlign: 'center',
  },

  [`& .${classes.block}`]: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    margin: '0 auto',
    padding: theme.spacing(2, 1),
    flexBasis: '33%',
    width: 'auto',
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
      width: '100%',
    },
  },

  [`& .${classes.hoursTable}`]: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'row wrap',
    maxWidth: '350px',
    alignItems: 'flex-start',
    rowGap: '1rem',
  },

  [`& .${classes.hourTitle}`]: {
    position: 'relative',
    display: 'inline-block',
    flexBasis: '50%',
    textAlign: 'right',
    paddingRight: theme.spacing(2),
  },

  [`& .${classes.hourInfo}`]: {
    position: 'relative',
    display: 'inline-block',
    flexBasis: '50%',
  },

  [`& .${classes.newhoursTable}`]: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'row wrap',
    maxWidth: '360px',
    alignItems: 'flex-start',
    rowGap: '1rem',
  },

  [`& .${classes.newhourTitle}`]: {
    position: 'relative',
    display: 'inline-block',
    flexBasis: '33%',
    textAlign: 'right',
    paddingRight: theme.spacing(2),
    fontWeight: 'bold',
  },

  [`& .${classes.newhourInfo}`]: {
    position: 'relative',
    display: 'inline-block',
    flexBasis: '66%',
    textAlign: 'left',
  },

  [`& .${classes.mapWrap}`]: {
    width: '100%',
    minWidth: '100%',
    height: 'auto',
  },

  [`& .${classes.button}`]: {
    color: theme.palette.text.secondary,
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export function Footer() {
  return (
    <FooterWrap>
      <motion.section
        variants={childrenVariants}
        transition={transitionChildrenFast}
        className={classes.section}
      >
        <div className={classes.block}>
          <Typography
            variant={'h4'}
            component={'h2'}
            className={classes.title}
            gutterBottom
          >
            Hours
          </Typography>
          <div className={classes.newhoursTable}>
            {[
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
            ].map((day) => (
              <Fragment key={day}>
                <Typography
                  variant={'body1'}
                  className={classes.newhourTitle}
                  gutterBottom
                >
                  {day}
                </Typography>
                <Typography
                  variant={'body1'}
                  gutterBottom
                  className={classes.newhourInfo}
                >
                  9AM-12AM
                </Typography>
              </Fragment>
            ))}
            {['Saturday', 'Sunday'].map((day) => (
              <Fragment key={day}>
                <Typography
                  variant={'body1'}
                  className={classes.newhourTitle}
                  gutterBottom
                >
                  {day}
                </Typography>
                <Typography
                  variant={'body1'}
                  gutterBottom
                  className={classes.newhourInfo}
                >
                  {day === 'Sunday' ? 'Closed' : '10AM-12AM'}
                </Typography>
              </Fragment>
            ))}
          </div>
        </div>
        <div className={classes.block}>
          <Typography
            variant={'h4'}
            className={classes.title}
            gutterBottom
          >
            Location
          </Typography>
          <Button
            href={'https://goo.gl/maps/om1cUZ55uMP6kmSaA'}
            target={'_blank'}
            variant={'text'}
            component={'a'}
            className={classes.button}
          >
            <span>1413 Haven Dr.</span>
            <span>&nbsp;Orlando, FL</span>
            <span>&nbsp;32803</span>
          </Button>
          <div className={clsx(classes.block, classes.mapWrap)}>
            <iframe
              title='map'
              src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14016.68803734699!2d-81.3660057!3d28.5645971!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e7710f4e03dd85%3A0x116b68901e7aa1b7!2sZ%20Co-Space%20%26%20Clubhouse!5e0!3m2!1sen!2sus!4v1690122333302!5m2!1sen!2sus'
              width='150'
              height='150'
              allowFullScreen
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
              style={{
                border: 0,
                width: 'auto',
              }}
            />
          </div>
        </div>
        <div className={classes.block}>
          <Typography
            variant={'h4'}
            className={classes.title}
            gutterBottom
          >
            Contact
          </Typography>
          <Button
            component={'a'}
            href={'tel:+14079868260'}
            target={'_blank'}
            variant={'text'}
            className={classes.button}
          >
            407.986.8260
          </Button>
          <Button
            href={'mailto:info@zcospaceclubhouse.com'}
            target={'_blank'}
            component={'a'}
            variant={'text'}
            className={classes.button}
          >
            info@zcospaceclubhouse.com
          </Button>
        </div>
      </motion.section>
    </FooterWrap>
  )
}
