import { useRouter } from 'next/router'
import {
  Context,
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react'
import { produce } from 'immer'
import { cookieService } from '@Services/cookie-service'
import { NewsletterPromoCodes } from '@Config/emails/types'

enum NEWSLETTER_LOCAL_PREF {
  initial = 'opened',
  dismissed = 'dismissed',
  subscribed = 'subscribed',
}

const NEWSLETTER_MODAL_STORAGE_KEY = 'newsletter-state'
const getLocalPref = () =>
  cookieService.getItem(NEWSLETTER_MODAL_STORAGE_KEY)

type NewsletterAction =
  | { type: 'close'; ad: boolean; callback?: () => void }
  | { type: 'open'; ad: boolean }
  | { type: 'subscribeSuccess' }

type NewsletterDispatch = (action: NewsletterAction) => void

export interface NewsletterContextState {
  isOpen: boolean
  isAd: boolean
  subscribed: boolean
}

// keeping the newsletter hidden initially
const initialNewsletterContext: NewsletterContextState = {
  isOpen: false,
  isAd: false,
  subscribed: false,
}

const NewsletterContext: Context<{
  state: NewsletterContextState
  dispatch: NewsletterDispatch
}> = createContext({
  dispatch: (_action) => {},
  state: initialNewsletterContext,
})

function reducer(
  state: NewsletterContextState,
  dispatch: NewsletterAction,
) {
  //todo
  // manage newsletter popups to open still if ad dismissed, otherwise, hide
  switch (dispatch.type) {
    case 'close':
      //todo check type of close, if normal (not ad), close with dismissed cookie
      if (!state.subscribed) {
        cookieService.setItem(
          NEWSLETTER_MODAL_STORAGE_KEY,
          NEWSLETTER_LOCAL_PREF.dismissed,
          {
            maxAge: 259200,
          },
        )
      }
      if (typeof dispatch.callback === 'function') {
        dispatch.callback()
      }
      return produce(state, (draft) => {
        draft.isOpen = false
      })
    case 'open':
      //todo
      // check type
      return produce(state, (draft) => {
        draft.isOpen = true
        draft.isAd = dispatch.ad
      })
    case 'subscribeSuccess':
      //todo
      // nothing?
      cookieService.setItem(
        NEWSLETTER_MODAL_STORAGE_KEY,
        NEWSLETTER_LOCAL_PREF.subscribed,
        {
          maxAge: 259200,
        },
      )
      return produce(state, (draft) => {
        draft.subscribed = true
      })
    default:
      return state
  }
}

export const NewsletterContextProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[]
}) => {
  const router = useRouter()
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const [state, dispatch] = useReducer(
    reducer,
    initialNewsletterContext,
  )

  useEffect(() => {
    // the syndicate page is a special case,
    // where we want to show the newsletter directly in the page,
    // not in the modal
    if (router.pathname === '/syndicate') {
      console.log('syndicate page, no modal!')
      return
    }
    const pastPref = getLocalPref()
    // if cookie has not been set, set one.
    if (
      pastPref !== NEWSLETTER_LOCAL_PREF.dismissed &&
      pastPref !== NEWSLETTER_LOCAL_PREF.subscribed
    ) {
      cookieService.setItem(
        NEWSLETTER_MODAL_STORAGE_KEY,
        NEWSLETTER_LOCAL_PREF.initial,
        {
          maxAge: 259200,
        },
      )
      if (
        router.query &&
        'promo' in router.query &&
        decodeURIComponent(String(router.query.promo)) ===
          NewsletterPromoCodes.freekava2021
      ) {
        console.log('opening newsletter for ad modal')
        timeoutRef.current = setTimeout(
          () => dispatch({ type: 'open', ad: true }),
          2000,
        )
      } else {
        console.log('opening newsletter subscribe modal')
        timeoutRef.current = setTimeout(
          () => dispatch({ type: 'open', ad: false }),
          2000,
        )
      }
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [router.query, router.pathname])

  return (
    <NewsletterContext.Provider value={{ state, dispatch }}>
      {children}
    </NewsletterContext.Provider>
  )
}

export const useNewsletterContext = () =>
  useContext(NewsletterContext)
