import Image from 'next/image'

import lightLogo from '@Static/images/real-logo.png'
import styles from './logo.module.scss'

export const Logo = () => (
  <Image
    className={styles.logo}
    src={lightLogo}
    height={140}
    width={100}
    alt={'Z Cospace & Clubhouse Logo'}
  />
)
