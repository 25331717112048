import { Transition } from 'framer-motion'

export const transitionPrimary: Transition = {
  type: 'tween',
  duration: 1,
  ease: [0.165, 0.44, 0.84, 1],
  staggerChildren: 0.04,
}

export const transitionChildren: Transition = {
  type: 'tween',
  duration: 0.5,
  ease: [0.165, 0.84, 0.44, 1],
}

export const transitionChildrenFast: Transition = {
  type: 'tween',
  duration: 0.27,
  ease: [0.165, 0.84, 0.44, 1],
}
