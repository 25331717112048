import Script from 'next/script'
import type { AppProps } from 'next/app'
import { DefaultSeo } from 'next-seo'
import { CacheProvider } from '@emotion/react'
import type { EmotionCache } from '@emotion/react'
import { AnimatePresence } from 'framer-motion'
import { Layout } from '@Components/Layout'
import { NewsletterContextProvider } from '@Components/context/Newsletter/newsletter-context'
import { ErrorBoundary } from '@Components/elements/ErrorBoundary/error-boundary'
import { nextSeoConfig } from 'next-seo.config'
import createEmotionCache from '@Config/theme/cache'
import { GA_TRACKING_ID } from '@Lib/gtag'

import 'react-multi-carousel/lib/styles.css'
import '@Styles/index.global.scss'
// import '@Static/fonts/jonathan/stylesheet.css'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
}

function App({
  pageProps,
  Component,
  router,
  emotionCache = clientSideEmotionCache,
}: MyAppProps) {
  return (
    <ErrorBoundary>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        strategy='afterInteractive'
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script
        id='gtag-init'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <DefaultSeo {...nextSeoConfig} />
      <CacheProvider value={emotionCache}>
        <NewsletterContextProvider>
          <AnimatePresence exitBeforeEnter>
            <Layout key={router.route}>
              <Component {...pageProps} />
            </Layout>
          </AnimatePresence>
        </NewsletterContextProvider>
      </CacheProvider>
    </ErrorBoundary>
  )
}

export default App
