export const commonColors = {
  primaryColor: '#696969',
  primaryColorLight: '#adadad',
  primaryColorDark: '#3f3f3f',
  secondaryColor: '#b24c81',
  secondaryColorLight: '#e561a5',
  secondaryColorDark: '#923e6a',
  errorColor: '#d50000',
  black: '#080808',
  paperBlack: '#1f1b1a',
  white: '#fbfbff',
  paperWhite: '#d9d9d9',
}
