export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID
export const GA_TRACKING_ID_ADS = process.env.NEXT_PUBLIC_GA_ID_ADS

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  if (
    typeof window !== 'undefined' &&
    'gtag' in window &&
    typeof (window as any).gtag === 'function'
  ) {
    ;(window as any).gtag('config', GA_TRACKING_ID, {
      page_path: url,
    })
    ;(window as any).gtag('config', GA_TRACKING_ID_ADS)
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: any) => {
  if (
    typeof window !== 'undefined' &&
    'gtag' in window &&
    typeof (window as any).gtag === 'function'
  ) {
    ;(window as any).gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    })
  }
}
