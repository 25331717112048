import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { motion, useReducedMotion } from 'framer-motion'
import { CssBaseline, LinearProgress } from '@mui/material'
import {
  styled,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles'
import { createMaterialTheme } from '@Config/theme/material-theme'
import { Header } from '@Components/Layout/Header'
import { Footer } from '@Components/Layout/Footer'
import { transitionPrimary } from '@Config/framer/transitions'
import * as gtag from '@Lib/gtag'

interface Layout {
  children: ReactNode | ReactNode[]
}

const PREFIX = 'layout'

const classes = {
  progressBar: `${PREFIX}-progressBar`,
}

const Root = styled(motion.div)({
  display: 'block',
  position: 'relative',
  opacity: 1,
  [`& .${classes.progressBar}`]: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 3140000,
    minWidth: '100vw',
    width: '100%',
    overflow: 'hidden',
    height: '6px',
    color: 'rgba(20, 20, 42, 0.8)',
  },
})

export function Layout({ children }: Layout) {
  //create theme
  const theme = createMaterialTheme(false)
  const [isPathChanging, setIsPathChanging] = useState(false)
  const [progress, setProgress] = useState(0)

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const progressTimerRef = useRef<ReturnType<typeof setInterval>>()
  const router = useRouter()
  const shouldReduceMotion = useReducedMotion()

  useEffect(() => {
    const enableLoader = (url: string) => {
      console.log('App is changing to: ', url)
      // enable loading animation through state
      setProgress(0)
      setIsPathChanging(true)
      progressTimerRef.current = setInterval(() => {
        setProgress((oldProgress) => {
          const diff = Math.random() * 69
          return Math.min(oldProgress + diff, 100)
        })
      }, 69)
    }
    const disableLoader = (url: string) => {
      console.log('App has changed to: ', url)
      // gtag pageview on completion of page change
      gtag.pageview(url)
      // disable loading animation through state
      setProgress(100)
      timeoutRef.current = setTimeout(() => {
        setIsPathChanging(false)
      }, 300)
    }

    try {
      router.events.on('routeChangeStart', enableLoader)
      router.events.on('routeChangeComplete', disableLoader)
      router.events.on('routeChangeError', disableLoader)
    } catch (e) {
      console.error(e)
    }
    // cleanup
    return () => {
      setProgress(0)
      try {
        router.events.off('routeChangeStart', enableLoader)
        router.events.off('routeChangeComplete', disableLoader)
        router.events.off('routeChangeError', disableLoader)
      } catch (e) {
        console.error(e)
      }
      if (timeoutRef?.current) {
        clearTimeout(timeoutRef.current)
      }
      if (progressTimerRef?.current)
        clearInterval(progressTimerRef.current)
    }
  }, [router.events])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Root
          initial={shouldReduceMotion ? 'pageAnimate' : 'pageInitial'}
          animate='pageAnimate'
          exit={shouldReduceMotion ? 'pageAnimate' : 'pageExit'}
          transition={transitionPrimary}
        >
          {isPathChanging && (
            <LinearProgress
              className={classes.progressBar}
              variant={'determinate'}
              value={progress}
              color='inherit'
            />
          )}
          <Header theme={theme} />
          <main id={'main'}>{children}</main>
          <Footer />
          {/*<UberEatsFAB />*/}
          {/*<NewsletterModal />*/}
        </Root>
        <CssBaseline />
        {/*@ts-ignore*/}
        <style jsx global>
          {`
            /**todo remove once mui fixes CssBaseline & emotion fixes ssr issues.
              * https://github.com/emotion-js/emotion/issues/2158
              */
            body {
              background-color: ${theme.palette.background.default};
              color: ${theme.palette.text.primary};
            }

            :root {
              // firefox
              scrollbar-width: thin;
              scrollbar-color: ${theme.palette.text.primary}
                ${theme.palette.grey['A100']};
              // make theme variables available to scss
              --primary-color: ${theme.palette.primary[
                theme.palette.mode
              ]};
              --secondary-color: ${theme.palette.secondary[
                theme.palette.mode
              ]};
              --text-primary: ${theme.palette.text.primary};
              --text-secondary: ${theme.palette.text.secondary};
              --bg-default: ${theme.palette.background.default};
              --bg-paper: ${theme.palette.background.paper};
              --shadow-color: 0deg 0% 50%;
            }

            ::-webkit-scrollbar {
              width: 6px;
            }

            ::-webkit-scrollbar-track {
              border-radius: 3px;
              box-shadow: 1px 2px 2px hsl(var(--shadow-color) / 0.333),
                2px 4px 4px hsl(var(--shadow-color) / 0.333),
                3px 6px 6px hsl(var(--shadow-color) / 0.333);
              background-color: ${theme.palette.text.primary};
            }

            ::-webkit-scrollbar-thumb {
              border-left: 1px solid ${theme.palette.text.primary};
              border-radius: 3px;
              background-color: ${theme.palette.grey['300']};
              box-shadow: 0.5px 1px 1px hsl(var(--shadow-color) / 0.7);
            }

            #calendarTitle {
              color: white !important;
            }
          `}
        </style>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
